// Core
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

// Instruments
import '../static/styles/index.scss';
import Layout from '../layouts/en';
import SEO from '../components/seo';
import MainSection from '../pages-sections/careers-sections/main-section/main-section';
// eslint-disable-next-line max-len
import VacanciesSection from '../pages-sections/careers-sections/vacancies-section/vacancies-section';
import SubscriptionSection from '../pages-sections/home-sections/subscription-section/subscription-section';


const IndexPage = ({ location, data }) => {
  const [windowSize, setWindowSize] = useState(0);
  const isMobile = windowSize <= 1024;
  const wpData = data && data.allWordpressPage.edges.filter((item) => item.node.title === 'Careers')[0].node.acf;
  const vacancies = data && data.allWordpressPost.edges;

  const handleWindowSizeChange = () => {
    const width = window.innerWidth || document.documentElement.clientWidth
      || document.body.clientWidth;
    setWindowSize(width);
  };

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);

  const renderSections = () => (
    [
      <MainSection
        activeSection={1}
        title={wpData.section_1_name}
        data={
          {
            title: wpData.section_1_title,
            titleBold: wpData.section_1_title_bold,
            image: wpData.section_1_background_image,
          }
        }
      />,
      <VacanciesSection
        data={{
          title: wpData.section_2_title,
          subTitle: wpData.section_2_sub_title,
          accordionTitle: wpData.section_2_table_title,
          text: wpData.section_2_text,
          buttonAction: wpData.section_2_button,
          button_back: wpData.button_back,
          input_name: wpData.input_name,
          input_company: wpData.input_company,
          input_email: wpData.input_email,
          input_phone: wpData.input_phone,
          input_text: wpData.input_text,
          attach: wpData.attach,
          submit_button: wpData.submit_button,
          success: wpData.success,
          error_empty: wpData.error_empty,
          email_invalid: wpData.email_invalid,
          phone_invalid: wpData.phone_invalid,
          error_file: wpData.error_file,
          file_format_title: wpData.file_format_title,
        }}
        vacancies={vacancies}
      />,
      <SubscriptionSection
        data={
      {
        title: wpData.subscription_title,
        subTitle: wpData.subscription_sub_title,
        placeholder: wpData.subscription_placeholder,
        button: wpData.subscription_button,
        empty: wpData.email_empty,
        exist: wpData.email_exist,
        success: wpData.success,
      }
    }
      />,
    ]
  );

  return (
    <>
      <Layout
        location={location}
        isMobile={isMobile}
        currentSection={1}
        sectionsCount={1}
        footerData={
          {
            footerLink1: wpData.footer_link_1,
            footerLink2: wpData.footer_link_2,
            footerLink3: wpData.footer_link_3,
            footerLink4: wpData.footer_link_4,
            footerLink5: wpData.footer_link_5,
            footerLink6: wpData.footer_link_6,
            footerLink7: wpData.footer_link_7,
            footerEmailLabel: wpData.footer_email_label,
            footerEmail: wpData.footer_email,
            footerPhoneLabel: wpData.footer_phone_label,
            footerPhone: wpData.footer_phone,
            copyright: wpData.copyright,
          }
        }
      >
        <SEO title={wpData.section_1_name} />
        <div data-currentPage={`currentPage_${1}`}>
          {renderSections().map((section) => section)}
        </div>
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};
IndexPage.defaultProps = {
  data: null,
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          acf {

            section_1_title
            section_1_title_bold
            section_1_name
            section_1_background_image {
              alt
              url
            }
            
            section_2_title
            section_2_sub_title
            section_2_table_title
            section_2_text
            section_2_button

            subscription_title
            subscription_sub_title
            subscription_placeholder
            subscription_button
            email_empty
            email_exist
            success
            
            button_back
            input_name
            input_company
            input_email
            input_phone
            input_text
            attach
            submit_button
            success
            error_empty
            email_invalid
            phone_invalid
            error_file
            file_format_title

            footer_link_1
            footer_link_2
            footer_link_3
            footer_link_4
            footer_link_5
            footer_link_6
            footer_link_7
            footer_email_label
            footer_email
            footer_phone_label
            footer_phone
            copyright
          }
          title
          slug
        }
      }
    }
    allWordpressPost(filter: {categories: {eq: 2}}) {
      edges {
        node {
          id
          date
          acf {
            vacancie_title
            vacancie_description
            vacancie_form_user_name
            vacancie_form_company_name
            vacancie_form_email
            vacancie_form_phone
            vacancie_form_message
            vacancie_form_file
            vacancie_form_submit
          }
        }
      }
    }
  }`;

import React from 'react';
import Modal from 'react-modal';
import './modals.css';
// eslint-disable-next-line react/prop-types
const Modals = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Modal {...props}>
    {children}
  </Modal>
);

export default Modals;

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './vacancies-section.module.scss';
import Container from '../../../layouts/container/container';
import VerticalLines from '../../../components/vertical-lines/ vertical-lines';
// import Accordion from '../../../lib/accordion/accordion';
import Modals from '../../../components/modals/modals';
import ContactForm from '../../../components/contact-form/contactForm';
import Arrow from '../../../assets/svg/arrow.svg';

const VacanciesSection = ({
  activeSection,
  data,
  // vacancies,
}) => {
  const [sectionLoaded, setSectionLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    setSectionLoaded(true);
  }, []);
  // const panels = vacancies.map((item) => ({
  //   label: item.node.acf.vacancie_title,
  //   content: (
  //     <>
  //       <p>{item.node.acf.vacancie_description}</p>
  //       <ContactForm
  //         data={{
  //           button_back: data.button_back,
  //           input_name: data.input_name,
  //           input_company: data.input_company,
  //           input_email: data.input_email,
  //           input_phone: data.input_phone,
  //           input_text: data.input_text,
  //           attach: data.attach,
  //           submit_button: data.submit_button,
  //           success: data.success,
  //           error_empty: data.error_empty,
  //           email_invalid: data.email_invalid,
  //           phone_invalid: data.phone_invalid,
  //           error_file: data.error_file,
  //           file_format_title: data.file_format_title,
  //         }}
  //         theme="vacancy"
  //       />
  //     </>),
  // }));

  return (
    <section data-active={`active_${sectionLoaded ? activeSection : 0}`} className={`${styles.vacanciesSection} ${styles.sections}`}>
      <VerticalLines startAnimation={sectionLoaded} />
      <Container>
        <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
          <div className={styles.animatedTextContainer}>
            <div className={styles.animatedText}>
              <h6 className={styles.h3}>{data.title}</h6>
            </div>
            <div className={styles.animatedText}>
              <p className={styles.p}>{data.subTitle}</p>
            </div>
          </div>
        </ScrollAnimation>
        {/* <Accordion panels={panels} title={data.accordionTitle} /> */}
        <ScrollAnimation animateOnce initiallyVisible animateIn={styles.animateIn}>
          <div className={styles.animatedTextContainer}>
            <div className={styles.animatedText}>
              <p className={styles.p}>{data.text}</p>
            </div>
            <div className={styles.animatedText}>
              <div className={styles.vacanciesSection_button__block}>
                <button
                  aria-label="button"
                  type="button"
                  onClick={() => setModalOpen(true)}
                  className={styles.vacanciesSection_button}
                >
                  {data.buttonAction}
                </button>
                <Arrow />
              </div>
            </div>
          </div>
          <Modals
            isOpen={modalOpen}
            onRequestClose={() => setModalOpen(false)}
            className="modal contact"
            overlayClassName="modal-overlay"
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
          >
            <ContactForm
              data={{
                button_back: data.button_back,
                input_name: data.input_name,
                input_company: data.input_company,
                input_email: data.input_email,
                input_phone: data.input_phone,
                input_text: data.input_text,
                attach: data.attach,
                submit_button: data.submit_button,
                success: data.success,
                error_empty: data.error_empty,
                email_invalid: data.email_invalid,
                phone_invalid: data.phone_invalid,
                error_file: data.error_file,
                file_format_title: data.file_format_title,
              }}
              theme="vacancy"
              btnBackHandler={() => setModalOpen(false)}
            />
          </Modals>
        </ScrollAnimation>
      </Container>
    </section>
  );
};

VacanciesSection.propTypes = {
  activeSection: PropTypes.number.isRequired,
  //   title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  // vacancies: PropTypes.arrayOf(PropTypes.any),
};
VacanciesSection.defaultProps = {
  // vacancies: [],
};

export default VacanciesSection;
